var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.invoice.orders,"fit":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"Créée le","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm._f("moment")(row.createdAt,'L à LT'),"placement":"top-start"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(row.createdAt,'L'))+" "),_c('i',{staticClass:"el-icon-info info-icon"})])])]}}])}),_c('el-table-column',{attrs:{"label":"Status","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isFulfilled())?_c('el-tag',{staticClass:"tag-success",attrs:{"type":"primary"}},[_vm._v(" Honoré ")]):(row.isProcessing())?_c('el-tag',{staticClass:"tag-waiting",attrs:{"type":"primary"}},[_vm._v(" Traitement ")]):_c('el-tag',{staticClass:"order-pending",attrs:{"type":"primary"}},[_vm._v(" En attente ")])]}}])}),_c('el-table-column',{attrs:{"label":"N° commande","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.number))])]}}])}),_c('el-table-column',{attrs:{"label":"Valeur faciale","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.$n(row.amount, 'currency')))])]}}])}),_c('el-table-column',{attrs:{"label":"Montant","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.$n(row.payableAmount, 'currency')))])]}}])}),_c('el-table-column',{attrs:{"label":"Paiement","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isPaid())?_c('el-tag',{staticClass:"tag-success",attrs:{"type":"primary"}},[_vm._v(" Honoré ")]):(row.isPartiallyPaid())?_c('el-tag',{staticClass:"tag-waiting",attrs:{"type":"primary"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":("Reste à payer " + (_vm.$n(row.payableAmountleft(), 'currency', 'fr'))),"placement":"top-start"}},[_c('span',[_vm._v(" Partiel "),_c('i',{staticClass:"el-icon-info"})])])],1):_c('el-tag',{staticClass:"order-pending",attrs:{"type":"primary"}},[_vm._v(" En attente ")])]}}])}),_c('el-table-column',{attrs:{"label":"Livraison","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isDelivered())?_c('el-tag',{staticClass:"tag-success",attrs:{"type":"primary"}},[_vm._v(" Commande livré ")]):_c('el-tag',{staticClass:"order-pending",attrs:{"type":"primary"}},[_vm._v(" Commande non livré ")])]}}])}),_c('el-table-column',{attrs:{"width":"60px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":("Ouvrir la commande " + (row.number)),"placement":"top"}},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-right","circle":""},on:{"click":function($event){return _vm.$router.push({name: 'OrderEdit', params: {id: row.id}})}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }