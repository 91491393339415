






















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { BankTransfer, Invoice, Manageable } from '@/models'
import { Form } from 'element-ui'

@Component({
  name: 'BankTransferList',
  components: {
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private invoice!: Invoice

  private bankTransfers: BankTransfer[] = []
  private bankTransfersLoading = true

  private dialogFormVisible = false
  private dialogStatus = ''
  private textMap = {
    update: "Edition d'un virement bancaire",
    create: "Création d'un virement bancaire"
  }

  private tempData = new BankTransfer({ invoice: this.invoice, manageable: new Manageable({ id: '' }) })

  private createOrUpdateLoading = false

  created() {
    this.getBankTransfers()
  }

  private async getBankTransfers() {
    this.bankTransfersLoading = true
    const { data } =
        await BankTransfer
          .where({ invoiceId: this.invoice.id })
          .page(1)
          .per(999)
          .stats({ total: 'count' }).all()

    this.bankTransfers = data
    this.bankTransfersLoading = false
  }

  get manageableId() {
    return this.tempData?.manageable?.id || ''
  }

  private resetTempData() {
    this.tempData = new BankTransfer({
      invoice: this.invoice,
      manageable: this.invoice.buyer ? this.invoice.buyer : this.invoice.wholesaler,
      amount: this.leftToPay()
    })
  }

  private handleCreate() {
    this.resetTempData()
    this.dialogStatus = 'create'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private handleUpdate(bankTransfer: BankTransfer) {
    this.tempData = bankTransfer
    this.dialogStatus = 'update'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async handleDelete(bankTransfer: BankTransfer, index: number) {
    await bankTransfer.destroy()

    this.bankTransfers.splice(index, 1)
    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
  }

  private createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      this.createOrUpdateLoading = true
      if (valid) {
        const data = this.tempData

        const with_ = this.dialogStatus === 'create' ? ['invoice.id', 'manageable.id'] : ['manageable.id']
        try {
          await data.save({ with: with_ })

          if (this.dialogStatus === 'create') {
            this.bankTransfers.push(data)
            this.$notify({
              title: 'Bank Transfer Created',
              message: 'Bank Transfer successfully created',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('update', data)
            this.$notify({
              title: 'Bank Transfer Updated',
              message: 'Bank Transfer successfully updated',
              type: 'success',
              duration: 2000
            })
          }
        } catch (err) {
          this.createOrUpdateLoading = false
        }
      }
      this.createOrUpdateLoading = false
      this.dialogFormVisible = false
    })
  }

  private leftToPay() {
    return this.invoice.amount - this.bankTransfers.reduce((acc, current) => acc + current.amount, 0)
  }

  private uploadCallback(file: any) {
    this.tempData.proofOfPayment = file.blob.signed_id
  }
}
