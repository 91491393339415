
























































































































import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { Buyer, Invoice } from '@/models/index'

@Component({
  name: 'InvoicesTable',
  components: {
    Pagination
  }
})
export default class extends Vue {
  private tableKey = 0
  private invoiceList: Invoice[] = []
  private invoiceListLoading = true
  private total = 0
  private listQuery = {
    page: 1,
    limit: 20,
    number: undefined,
    buyerId: '',
    paymentStatus: undefined,
    mode: 'standard'
  }

  private buyerList: any[] = []
  private buyerListLoading = true

  private paymentStatusOptions = [
    {
      value: 'unpaid',
      label: 'Non payée'
    },
    {
      value: 'paid',
      label: 'Payée'
    },
    {
      value: 'late',
      label: 'En retard'
    }
  ]

  private modeOptions = [
    {
      value: 'standard',
      label: 'Standard'
    },
    {
      value: 'commission',
      label: 'Commission'
    }
  ]

  created() {
    this.getListInvoice()
    this.getListBuyer()
  }

  private async getListInvoice() {
    this.invoiceListLoading = true
    const { data, meta } =
        await Invoice
          .where({ number: { prefix: this.listQuery.number } })
          .where({ buyerId: this.listQuery.buyerId })
          .where({ paymentStatus: this.listQuery.paymentStatus })
          .where({ mode: this.listQuery.mode })
          .where({ bulk: false })
          .includes(['buyer', 'wholesaler'])
          .page(this.listQuery.page)
          .per(this.listQuery.limit)
          .order({ issuedAt: 'desc' })
          .stats({ total: 'count' })
          .all()

    this.invoiceList = data
    this.total = meta.stats.total.count
    this.invoiceListLoading = false
  }

  private async getListBuyer() {
    this.buyerListLoading = true
    const { data } = await Buyer.per(999).page(1).order('name').all()

    const tempList: any = {}
    data.forEach(e => {
      const firstChar = e.name.charAt(0)
      if (!tempList[firstChar]) tempList[firstChar] = { label: firstChar, options: [e] }
      else tempList[firstChar].options.push(e)
    })

    this.buyerList = Object.values(tempList)
    this.buyerListLoading = false
  }

  private handleFilter() {
    this.listQuery.page = 1
    this.getListInvoice()
  }

  private handleBuyerSelect(buyerId: string) {
    this.listQuery.page = 1
    this.listQuery.buyerId = buyerId
    this.getListInvoice()
  }

  private async handleDelete(data: Invoice, index: number) {
    await data.destroy()

    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
    this.invoiceList.splice(index, 1)
    this.total -= 1
  }
}
