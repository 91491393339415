






















































































































import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { Invoice } from '@/models/index'
import DirectUpload from '@/components/DirectUpload/index.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { ITagView, TagsViewModule } from '@/store/modules/tags-view'

@Component({
  name: 'InvoiceEdit',
  components: {
    DirectUpload,
    VuePdfEmbed,
    BankTransfersList: () => import('./components/bank-transfers-list.vue'),
    CommissionInvoice: () => import('./components/commission-invoice.vue'),
    RelatedOrdersTable: () => import('./components/related-orders-table.vue'),
    OrderMovesTable: () => import('./components/balance-moves-table.vue')
  }
})
export default class extends Vue {
  private invoice: Invoice = new Invoice()
  private commissionInvoice: Invoice = new Invoice()
  private invoiceLoading = false
  private page = null
  private pdfLoading = true

  private paymentModeOptions = [
    {
      name: 'Prepaid',
      value: 'prepaid'
    },
    {
      name: 'Differed',
      value: 'differed'
    }
  ]

  created() {
    if (!this.isCreate) this.getInvoice()
  }

  get isCreate() {
    return this.$route.name === 'InvoiceCreate'
  }

  get loading() {
    return this.invoiceLoading
  }

  private async getInvoice() {
    this.invoiceLoading = true
    const { data } = await Invoice
      .includes(['buyer', 'wholesaler', 'seller', 'orders'])
      .selectExtra(['fileBlob'])
      .find(this.$route.params.id)

    this.invoice = data
    this.updateTagView(data)
    await this.getCommissionInvoice(data)
    this.invoiceLoading = false
  }

  private async getCommissionInvoice(invoice: Invoice) {
    const { data } = await Invoice
      .where({ invoiceId: invoice.id })
      .includes('seller')
      .selectExtra(['fileBlob'])
      .first()
    this.commissionInvoice = data || this.commissionInvoice
  }

  private uploadCallback(file: any) {
    this.invoice.file = file.blob.signed_id
  }

  get invoiceSeller() : boolean {
    return this.invoice?.buyer === undefined
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid: any) => {
      if (valid) {
        const data = this.invoice

        await data.save()

        if (this.isCreate) {
          this.$notify({
            title: 'Invoice created',
            message: 'Invoice successfully created',
            type: 'success',
            duration: 2000
          })
          this.$router.replace({ name: 'InvoiceEdit', params: { id: data.id } }).catch(err => err)
        } else {
          this.$notify({
            title: 'Invoice updated',
            message: 'Invoice successfully updated',
            type: 'success',
            duration: 2000
          })
        }
      }
    })
  }

  handleDocumentRender() {
    this.pdfLoading = false
  }

  mounted() {
    this.updateTagView()
  }

  updateTagView(invoice?: Invoice) {
    const matchTagView: any = TagsViewModule.visitedViews.filter(view => view.params?.id === this.$route.params.id)

    if (matchTagView.length === 1) {
      matchTagView[0].title = `Invoice ${invoice?.number || 'loading...'}`
      matchTagView[0].meta.title = `Invoice ${invoice?.number || 'loading...'}`
      TagsViewModule.updateVisitedView({ ...matchTagView[0] as ITagView })
    }
  }
}
