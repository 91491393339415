







































import { Component, Vue, Prop } from 'vue-property-decorator'
import { BalanceMove, Invoice } from '@/models/index'

@Component({
  name: 'BalanceMovesTable'
})
export default class extends Vue {
  @Prop({ required: true }) private invoice!: Invoice

  private balanceMoves: BalanceMove[] = []
  private tableKey = 0
  private orderBalancesLoading = false

  created() {
    this.getBalanceMoves()
  }

  private async getBalanceMoves() {
    this.orderBalancesLoading = true
    const { data } = await BalanceMove
      .where({ sourceId: this.invoice.id })
      .where({ sourceType: 'Invoice' })
      .all()

    this.balanceMoves = data
    this.orderBalancesLoading = false
  }
}
