








import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Invoices',
  components: {
    InvoicesTable: () => import('./invoices-table/invoices-table.vue'),
    InvoicesBulkTable: () => import('./invoices-bulk-table/invoices-table.vue')
  }
})
export default class extends Vue {
}
