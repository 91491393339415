

























































































































import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { Invoice, Seller } from '@/models/index'

@Component({
  name: 'InvoicesBulkTable',
  components: {
    Pagination
  }
})
export default class extends Vue {
  private tableKeyBulk = 1
  private invoiceBulkList: Invoice[] = []
  private invoiceListBulkLoading = true
  private totalBulk = 0
  private listQueryBulk = {
    page: 1,
    limit: 20,
    number: undefined,
    sellerId: '',
    paymentStatus: undefined,
    mode: 'standard'
  }

  private sellerList: any[] = []
  private sellerListLoading = true

  private paymentStatusOptions = [
    {
      value: 'unpaid',
      label: 'Non payée'
    },
    {
      value: 'paid',
      label: 'Payée'
    },
    {
      value: 'late',
      label: 'En retard'
    }
  ]

  private modeOptions = [
    {
      value: 'standard',
      label: 'Standard'
    },
    {
      value: 'commission',
      label: 'Commission'
    }
  ]

  created() {
    this.getListInvoiceBulk()
    this.getListSeller()
  }

  private async getListInvoiceBulk() {
    this.invoiceListBulkLoading = true
    const { data, meta } =
        await Invoice
          .where({ number: { prefix: this.listQueryBulk.number } })
          .where({ sellerId: this.listQueryBulk.sellerId })
          .where({ paymentStatus: this.listQueryBulk.paymentStatus })
          .where({ mode: this.listQueryBulk.mode })
          .where({ bulk: true })
          .includes(['seller', 'wholesaler'])
          .page(this.listQueryBulk.page)
          .per(this.listQueryBulk.limit)
          .order({ issuedAt: 'desc' })
          .stats({ total: 'count' })
          .all()

    this.invoiceBulkList = data
    this.totalBulk = meta.stats.total.count
    this.invoiceListBulkLoading = false
  }

  private async getListSeller() {
    this.sellerListLoading = true
    const { data } = await Seller.per(999).page(1).order('name').all()

    const tempList: any = {}
    data.forEach(e => {
      const firstChar = e.name.charAt(0)
      if (!tempList[firstChar]) tempList[firstChar] = { label: firstChar, options: [e] }
      else tempList[firstChar].options.push(e)
    })

    this.sellerList = Object.values(tempList)
    this.sellerListLoading = false
  }

  private handleFilterBulk() {
    this.listQueryBulk.page = 1
    this.getListInvoiceBulk()
  }

  private handleSellerSelect(sellerId: string) {
    this.listQueryBulk.page = 1
    this.listQueryBulk.sellerId = sellerId
    this.getListInvoiceBulk()
  }

  private async handleDeleteBulk(data: Invoice, index: number) {
    await data.destroy()

    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
    this.invoiceBulkList.splice(index, 1)
    this.totalBulk -= 1
  }
}
