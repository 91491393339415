




































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Invoice } from '@/models/index'
import DirectUpload from '@/components/DirectUpload/index.vue'
import { Form } from 'element-ui'

@Component({
  name: 'CommissionInvoiceForm',
  components: {
    DirectUpload
  }
})
export default class extends Vue {
  @Prop({ required: true }) private commissionInvoice!: Invoice
  @Prop({ required: true }) private invoice!: Invoice
  @Prop({ required: false }) private title!: string
  @Prop({ required: true }) private update!: string

  private tempData: Invoice = new Invoice({ mode: 'commission', invoice: this.invoice })
  loading = false

  private paymentModeOptions = [
    {
      name: 'Prepaid',
      value: 'prepaid'
    },
    {
      name: 'Differed',
      value: 'differed'
    }
  ]

  created() {
    if (this.commissionInvoice?.id) this.tempData = this.commissionInvoice.dup()
  }

  private uploadCallback(file: any) {
    this.tempData.file = file.blob.signed_id
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid: any) => {
      if (valid) {
        this.loading = true
        await this.tempData.save({ with: 'invoice.id' })

        if (!this.update) {
          this.$notify({
            title: 'Commision invoice created',
            message: 'Commision invoice successfully created',
            type: 'success',
            duration: 2000
          })
        } else {
          this.$notify({
            title: 'Commision invoice updated',
            message: 'Commision invoice successfully updated',
            type: 'success',
            duration: 2000
          })
        }
        this.loading = false
        this.$emit('update', this.tempData)
      }
    })
  }
}
