



























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Invoice } from '@/models/index'
import DirectUpload from '@/components/DirectUpload/index.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

@Component({
  name: 'CommissionInvoice',
  components: {
    DirectUpload,
    VuePdfEmbed,
    CommissionInvoiceForm: () => import('./components/commission-invoice-form.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private commissionInvoice!: Invoice
  @Prop({ required: true }) private invoice!: Invoice

  private page = null

  get comInvoiceIsCreated(): boolean {
    return !!this.commissionInvoice?.id
  }
}
